<script lang="ts" setup>
import Parallaxy from '@lucien144/vue3-parallaxy'
import Link2 from '@/components/Button/Link2.vue'
import Title1 from '@/components/Title/Title1.vue'
import { type HomeSectionList } from '@/utils/types'
import { usePageStore } from '@/stores'
const pageStore = usePageStore()
const blockData = computed(() => pageStore.homeHenceArticles)

const props = defineProps({
  bgColor: {
    type: String,
    default: ''
  }
})
// 效果設定
const speedAnddirection = [
  {
    speed: 90,
    direction: 'normal'
  },
  {
    speed: 60,
    direction: 'normal'
  },
  {
    speed: 80,
    direction: 'opposite'
  },
  {
    speed: 80,
    direction: 'normal'
  }
]

const lists: Ref<HomeSectionList[]> = ref([])
blockData.value.forEach((o: any, index: number) => {
  const item: HomeSectionList = {
    pic: o.image,
    title: o.subject,
    text: o.description,
    link: `/hence/article/${o.id}`,
    ...speedAnddirection[index]
  }
  lists.value.push(item)
})
</script>

<template>
  <section class="section-box">
    <div class="wrap">
      <div class="section-number">
        <Parallaxy :breakpoints="{ 991: { axis: 'y', speed: 120 } }" :speed="0" direction="opposite"
          :animation="(delta: number) => `transform: translate3d(0, ${delta}px, 0);`">
          <SectionNumber no="1" :class="{ white: props.bgColor === '#cbc5bc' }" />
        </Parallaxy>
      </div>
      <div class="section-title">
        <Title1 class="section-title__inner">
          <template v-slot:logo>
            <Nimg src="./images/hence_logo.svg" class="section-title__logo" format="" />
          </template>
          <template v-slot:tw>看見另一種<br />過生活的方式</template>
          <template v-slot:link>
            <Link2 to="/hence" linkTitle="立即探索" class="circle"></Link2>
          </template>
        </Title1>
      </div>
      <div v-for="(item, index) in lists" :key="index" :class="`lists lists-${index + 1}`">
        <Parallaxy :breakpoints="{ 991: { axis: 'y', speed: item.speed } }" :speed="0" :direction="item.direction"
          :animation="(delta: number) => `transform: translate3d(0, ${delta}px, 0);`">
          <div class="lists__pic">
            <nuxt-link :to="item.link">
              <Nimg :src="item.pic?.link" :alt="item.pic?.alt" :title="item.pic?.title" />
            </nuxt-link>
          </div>
          <h3 class="lists__title">
            <nuxt-link :to="item.link">{{ item.title }}</nuxt-link>
          </h3>
          <div class="lists__text" v-if="item.text" v-html="item.text"></div>
          <Link2 :to="item.link" :title="item.title" class="circle" v-if="item.link" />
        </Parallaxy>
      </div>
    </div>
  </section>
</template>

<style lang="scss" scoped>
.section-box {
  .wrap {
    position: relative;
    @include grid;

    @include min-media(991) {
      height: toRem(1300);
      margin-bottom: toRem(80);
    }

    @include max-media(990) {
      max-width: toRem(520);
      @include grid(4, 24);
    }
  }

  .section-title {
    position: absolute;
    left: 0;
    top: toRem(200);
    width: 100%;
    @include grid;

    @include max-media(990) {
      position: relative;
      grid-column: 1 / span 4;
      @include grid(4, 24);
      top: 0;
      margin-bottom: toRem(35);
    }

    &__inner {
      grid-column: 7 / span 3;

      @include max-media(990) {
        grid-column: 2 / span 3;
      }
    }

    &__logo {
      display: block;
      width: 100%;
      max-width: toRem(180);
      margin-bottom: toRem(25);
    }
  }
}

.section-number {
  width: toRem(86);
  height: toRem(83);
  grid-column: 5 / span 1;

  @include min-media(991) {
    transform: translateY(-50px);
  }

  @include max-media(990) {
    grid-column: 1 / span 1;
    width: 100%;
    height: auto;
    margin-bottom: toRem(20);
  }
}

.lists {
  position: relative;

  @include max-media(990) {
    margin-bottom: toRem(24);
  }

  &-1 {
    grid-column: 1 / span 4;
  }

  &-2 {
    @include min-media(991) {
      grid-column: 5 / span 4;
      margin-top: 100%;
    }

    @include max-media(990) {
      grid-column: 1 / span 4;
    }
  }

  &-3 {
    @include min-media(991) {
      grid-column: 9 / span 2;
      margin-top: 200%;
    }

    @include max-media(990) {
      grid-column: 1 / span 3;
    }
  }

  &-4 {
    @include min-media(991) {
      grid-column: 11 / span 2;
    }

    @include max-media(990) {
      grid-column: 2 / span 3;
    }
  }

  &__pic {
    position: relative;
    padding-top: 100%;
    margin-bottom: toRem(10);

    img {
      display: block;
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  &__title {
    margin-bottom: toRem(10);
    font-weight: 700;
    font-size: toRem(22);
    @include dot-many-line;
  }

  &__text {
    margin-bottom: toRem(15);
    @include dot-many-line;
  }
}
</style>
